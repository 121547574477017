<script>
    export default {
      name: "FooterElement"
    };
</script>
<template>
    <footer>
        <h1>All copyrights reserved</h1>
    </footer>
</template>
<style scoped>
  footer{
   background-color:rgb(0, 0, 0);
   height:fit-content;
   width: 100%;
   color:aliceblue;
   font-family: sans-serif;
   text-align:center;
   

  }
</style>
import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
    {
      path: '/',
      name: 'home',
      component: () => import('../views/HomeView.vue')
    },
    {
      path: '/about',
      name: 'about',
      component: () => import('../views/AboutView.vue')
    },
    {
      path:'/showcases',
      name:'showcases',
      component:() => import('../views/showcases.vue')
    },
    
    {
      path:'/team',
      name:'team',
      component:() => import('../views/teammembers.vue')
    },
    
    {
      path:'/login',
      name:'signin',
      component:() => import('../views/LoginView.vue')
    },
  ]
  
  const router = createRouter({
    history: createWebHashHistory(),
    routes
  })
  
  export default router
  
<template>
    <navbar />
  <Transition mode="out-in">
    <router-view />
  </Transition>
  <Footer/>
</template>


<script>
import navbar from "./components/navbar.vue";
import Footer from "./components/Footer.vue";

export default {
  name: "FixedItems" ,
  components: { navbar, Footer }
};
</script>



<style>
*{
  margin:0px;
  padding:0px;
  overflow-x:hidden;

}

</style>
